export const PUR_INVITE_PERMISSION_PM_ORDERER = 'pur.invite.pm.orderer';
export const PUR_INVITE_PERMISSION_MANAGER = 'pur.invite.manager';
export const PUR_INVITE_PERMISSION_PAYMENTS_MANAGER = 'pur.invite.payments.manager';
export const PUR_INVITE_PERMISSION_ADMIN = 'pur.invite.admin';
export const PUR_INVITE_PERMISSION_OWNER = 'pur.invite.owner';

export const INVITE_PERMISSIONS = {
  pm_orderer: PUR_INVITE_PERMISSION_PM_ORDERER,
  manager: PUR_INVITE_PERMISSION_MANAGER,
  payments_manager: PUR_INVITE_PERMISSION_PAYMENTS_MANAGER,
  admin: PUR_INVITE_PERMISSION_ADMIN,
  owner: PUR_INVITE_PERMISSION_OWNER,
};
