import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export const useMembershipCreate = () => {
  const { mutate, onError, onDone } = useMutation(MEMBERSHIP_CREATE_MUTATION);

  onError((err) => {
    console.error('memberships - useMembershipCreate', err);
  });

  return {
    createMembership: (variables) => mutate(variables),
    onError,
    onDone,
  };
};

export const MEMBERSHIP_CREATE_MUTATION = gql`
  mutation createMembership($createParams: MembershipCreateInput!) {
    membershipCreate(createParams: $createParams) {
      id
      userId
      businessId
      role
    }
  }
`;

export const useMembershipDelete = () => {
  const { mutate, onError, onDone } = useMutation(MEMBERSHIP_DELETE_MUTATION);

  onError((err) => {
    console.error('memberships - useMembershipDelete', err);
  });

  return {
    deleteMembership: (variables) => mutate(variables),
    onError,
    onDone,
  };
};

export const MEMBERSHIP_DELETE_MUTATION = gql`
  mutation deleteMembership($id: ID!) {
    membershipDelete(id: $id)
  }
`;

export const useMembershipUpdate = () => {
  const { mutate, onError, onDone } = useMutation(MEMBERSHIP_UPDATE_MUTATION);

  onError((err) => {
    console.error('memberships - useMembershipUpdate', err);
  });

  return {
    updateMembership: (variables) => mutate(variables),
    onError,
    onDone,
  };
};

export const MEMBERSHIP_UPDATE_MUTATION = gql`
  mutation updateMembership($updateParams: MembershipUpdateInput!) {
    membershipUpdate(updateParams: $updateParams)
  }
`;

export const useMemberships = (variables) => {
  const { error } = useNotification();
  const { result, loading, refetch, onError, onResult } = useQuery(GET_MEMBERSHIPS, variables, () => ({
    enabled: !!variables.value.getParams.businessId,
  }));

  const memberships = useResult(result, []);

  onError((err) => {
    console.error('userMemberships', err);
    error();
  });

  return {
    memberships,
    loading,
    refetch,
    onResult,
  };
};

export const GET_MEMBERSHIPS = gql`
  query getMembership($getParams: MembershipInput!) {
    memberships(getParams: $getParams) {
      nodes {
        id
        role
        businessId
        userId
        user {
          firstName
          lastName
          email
          phone_number
          picture
        }
      }
    }
  }
`;
