<template>
  <Table
    v-loading="membershipsLoading"
    custom-class="memberships-table"
    :data="memberships"
    :columns="columns"
    show-index
    rounded
    border
  >
    <template #cell-actions="{ rowIndex }">
      <el-dropdown
        v-if="hasPermissionToEditRole(memberships[rowIndex].role)"
        class="d-flex justify-content-center"
        trigger="click"
        @command="(command) => handleAction(command, rowIndex)"
      >
        <Button :id="`actions-row-${rowIndex}`" type="icon" class="actions-btn text-typography-primary">
          <KebabIcon />
        </Button>
        <el-dropdown-menu>
          <el-dropdown-item :command="ACTIONS.EDIT_USER">
            <div class="d-flex align-items-center gap-2 ps-2">
              <EditIcon :size="26" />
              <p>{{ $t('memberships.membershipTable.actions.edit') }}</p>
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="ACTIONS.DELETE_USER_ACCESS">
            <div class="action-delete d-flex align-items-center gap-2 ps-2">
              <UnlinkV2Icon />
              <p>{{ $t('memberships.membershipTable.actions.delete') }}</p>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template
      #cell-name="{
        rowData: {
          user: { firstName, lastName, picture },
        },
      }"
    >
      <div class="name">
        <template v-if="picture">
          <img :src="`${picture}&token=${token}`" alt="" width="24" height="24" />
        </template>
        <template v-else>
          <div
            class="bg-secondary rounded-pill d-flex justify-content-center align-items-center"
            style="height: 24px; width: 24px"
          >
            <small class="icon-text">{{ firstName[0] + lastName[0] }}</small>
          </div>
        </template>
        <p>{{ `${firstName} ${lastName}` }}</p>
      </div>
    </template>
    <template
      #cell-email="{
        rowData: {
          user: { email },
        },
      }"
    >
      {{ email }}
    </template>
    <template
      #cell-phoneNumber="{
        rowData: {
          user: { phone_number },
        },
      }"
    >
      {{ phone_number ? phone_number : '-' }}
    </template>
    <template #cell-role="{ rowData: { role } }">
      <p v-if="isCurrentTenantBookkeeping && role === ROLE_TYPE.MANAGER">
        {{ $t('memberships.membershipTable.role.bookkeepingEmployee') }}
      </p>
      <p v-else-if="isCurrentTenantSupplier && role === ROLE_TYPE.EMPLOYEE">
        {{ $t('memberships.membershipTable.role.supplierEmployee') }}
      </p>
      <p v-else>{{ $t(`memberships.membershipTable.role.${role}`) }}</p>
    </template>
  </Table>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';

import { Table, Button } from '@/modules/core';
import { EditIcon, KebabIcon, UnlinkV2Icon } from '@/assets/icons';
import { useUser, useUserPermissions, useTenancy } from '@/modules/auth';
import { ROLE_TYPE, BUSINESS_TYPE } from '@/modules/users/constants';
import { INVITE_PERMISSIONS } from '../constants';

const TABLE_HEADERS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ROLE: 'role',
  ACTIONS: 'actions',
};

const ACTIONS = {
  DELETE_USER_ACCESS: 'delete',
  EDIT_USER: 'edit',
};

export default {
  name: 'MembershipManagementTable',
  components: { Table, KebabIcon, UnlinkV2Icon, Button, EditIcon },
  props: {
    memberships: { type: Array, required: true },
    membershipsLoading: { type: Boolean, required: true },
    allowedRoles: { type: Array, required: true },
  },
  emits: ['on-select-delete'],
  setup(_, { emit }) {
    const root = getCurrentInstance().proxy;

    const { currentTenant } = useTenancy();
    const { token } = useUser();
    const { isUserPermittedForActiveTenantNonReactive } = useUserPermissions();

    const columns = [
      { header: root.$t(`memberships.membershipTable.headers.${TABLE_HEADERS.NAME}`), key: TABLE_HEADERS.NAME },
      { header: root.$t(`memberships.membershipTable.headers.${TABLE_HEADERS.EMAIL}`), key: TABLE_HEADERS.EMAIL },
      {
        header: root.$t(`memberships.membershipTable.headers.${TABLE_HEADERS.PHONE_NUMBER}`),
        key: TABLE_HEADERS.PHONE_NUMBER,
      },
      { header: root.$t(`memberships.membershipTable.headers.${TABLE_HEADERS.ROLE}`), key: TABLE_HEADERS.ROLE },
      { header: '', key: TABLE_HEADERS.ACTIONS, width: '2.5rem', customClass: 'p-0 justify-content-end' },
    ];

    const isCurrentTenantSupplier = computed(() => currentTenant.value.type === BUSINESS_TYPE.SUPPLIER);
    const isCurrentTenantBookkeeping = computed(() => currentTenant.value.type === BUSINESS_TYPE.BOOK_KEEPING);

    const handleAction = (command, rowIndex) => {
      if (command === ACTIONS.DELETE_USER_ACCESS) {
        emit('on-select-delete', rowIndex);
      } else if (command === ACTIONS.EDIT_USER) {
        emit('on-select-edit', rowIndex);
      }
    };

    const updateMembership = (updatePayload) => {
      emit('on-membership-update', updatePayload);
    };

    const hasPermissionToEditRole = (role) => {
      switch (role.toUpperCase()) {
        case ROLE_TYPE.ORDERER:
        case ROLE_TYPE.PURCHASE_MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.pm_orderer);
        case ROLE_TYPE.MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.manager);
        case ROLE_TYPE.PAYMENTS_MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.payments_manager);
        case ROLE_TYPE.OWNER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.owner);
        default:
          return false;
      }
    };

    return {
      ACTIONS,
      ROLE_TYPE,
      token,
      columns,
      isCurrentTenantSupplier,
      isCurrentTenantBookkeeping,
      handleAction,
      updateMembership,
      hasPermissionToEditRole,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.memberships-table {
  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.action-delete {
  color: #e52044;
}

.role-button {
  color: black;
}

.name {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon-text {
    color: #9295a5;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.vector {
  display: none;
}

.role-button:hover {
  .vector {
    display: block;
  }
}
</style>
