import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useBusinessRelationsCreate = (onDoneCallback) => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(PERMISSION_CREATE_MUTATION);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('relations - useBusinessRelationsCreate', err);
  });

  onDone(() => {
    $message.success($t('relations.businessesTable.addPermissionModal.addSuccess'));
    onDoneCallback();
  });

  return {
    createBusinessRelation: mutate,
    onError,
    onDone,
    loading,
  };
};

export const PERMISSION_CREATE_MUTATION = gql`
  mutation businessToBusinessRelationCreate($businessToBusinessRelation: BusinessToBusinessRelationInput!) {
    businessToBusinessRelationCreate(businessToBusinessRelation: $businessToBusinessRelation) {
      providerBusinessId
      receiverBusinessId
      type
    }
  }
`;
