import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useBusinessRelationsDelete = (onDoneCallback) => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(PERMISSION_DELETE);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('business relations - useBusinessRelationsDelete', err);
  });

  onDone(() => {
    $message.success($t('relations.businessesTable.deletePermission.deleteSucess'));
    onDoneCallback();
  });

  return {
    deleteBusinessRelation: mutate,
    onError,
    onDone,
    loading,
  };
};

export const PERMISSION_DELETE = gql`
  mutation businessToBusinessRelationDelete($id: String) {
    businessToBusinessRelationDelete(id: $id)
  }
`;
