<template>
  <el-select
    v-if="isBusinessBookkeeping || isBusinessSupplier"
    :size="mini ? 'mini' : 'large'"
    :value="
      isBusinessSupplier
        ? $t('memberships.membershipTable.role.supplierEmployee')
        : $t('memberships.membershipTable.role.bookkeepingEmployee')
    "
    :disabled="true"
    class="role-select"
  />
  <el-select
    v-else
    :value="value"
    filterable
    :size="mini ? 'mini' : 'large'"
    :disabled="disabled"
    :placeholder="$t('memberships.membershipTable.headers.role')"
    class="role-select"
    @change="(updatedVal) => $emit('on-role-change', updatedVal)"
  >
    <el-option
      v-for="(roleIter, roleIndex) in allowedRoles"
      :key="`role-${roleIndex}`"
      :value="roleIter"
      :label="$t(`memberships.membershipTable.role.${roleIter}`)"
      :disabled="!hasPermissionToInviteRole(roleIter)"
      @mouseover.native="handleItemMouseEnter"
      @mouseleave.native="handleItemMouseLeave"
    >
      <template #default>
        <div
          v-if="!hasPermissionToInviteRole(roleIter)"
          class="role-disabled-item-content d-flex justify-content-between align-items-center"
        >
          <span>
            {{ $t(`memberships.membershipTable.role.${roleIter}`) }}
          </span>
          <el-tooltip :content="$t('memberships.membershipTable.noPermission')" placement="top" transition="">
            <LockFilledIcon />
          </el-tooltip>
        </div>
        <div v-else>
          {{ $t(`memberships.membershipTable.role.${roleIter}`) }}
        </div>
      </template>
    </el-option>
  </el-select>
</template>
<script>
import { computed } from 'vue';
import { useUserPermissions } from '@/modules/auth';
import { LockFilledIcon } from '@/assets/icons';

import { INVITE_PERMISSIONS } from '../constants';
import { BUSINESS_TYPE, ROLE_TYPE } from '@/modules/users/constants';

export default {
  name: 'MembershipRoleDropdown',
  components: { LockFilledIcon },
  props: {
    value: { type: String, default: null },
    businessIdToInviteTo: { type: String, default: null },
    businessType: { type: String, default: BUSINESS_TYPE.RESTAURANT },
    allowedRoles: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
  },
  emits: ['on-role-change'],
  setup(props, { emit }) {
    const { isUserPermittedForSpecificTenantNonReactive, isUserPermittedForActiveTenantNonReactive } =
      useUserPermissions();

    const hasPermissionToInviteRole = (role) => {
      const callBackForUserPermission = props.businessIdToInviteTo
        ? (permission) => isUserPermittedForSpecificTenantNonReactive(props.businessIdToInviteTo, permission)
        : isUserPermittedForActiveTenantNonReactive;

      switch (role.toUpperCase()) {
        case ROLE_TYPE.ORDERER:
        case ROLE_TYPE.PURCHASE_MANAGER:
          return callBackForUserPermission(INVITE_PERMISSIONS.pm_orderer);
        case ROLE_TYPE.MANAGER:
          return callBackForUserPermission(INVITE_PERMISSIONS.manager);
        case ROLE_TYPE.PAYMENTS_MANAGER:
          return callBackForUserPermission(INVITE_PERMISSIONS.payments_manager);
        case ROLE_TYPE.OWNER:
          return callBackForUserPermission(INVITE_PERMISSIONS.owner);
        case ROLE_TYPE.ADMIN:
          return callBackForUserPermission(INVITE_PERMISSIONS.admin);
        default:
          return false;
      }
    };

    const handleItemMouseEnter = (event) => {
      event.currentTarget.classList.add('hover');
    };

    const handleItemMouseLeave = (event) => {
      event.currentTarget.classList.remove('hover');
    };

    const isBusinessBookkeeping = computed(() => {
      if (props.businessType === BUSINESS_TYPE.BOOK_KEEPING) {
        emit('on-role-change', ROLE_TYPE.MANAGER);
        return true;
      }
      return false;
    });

    const isBusinessSupplier = computed(() => {
      if (props.businessType === BUSINESS_TYPE.SUPPLIER) {
        emit('on-role-change', ROLE_TYPE.EMPLOYEE);
        return true;
      }
      return false;
    });

    return {
      isBusinessSupplier,
      isBusinessBookkeeping,
      handleItemMouseEnter,
      handleItemMouseLeave,
      hasPermissionToInviteRole,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-select-dropdown__item {
    &.hover {
      background-color: #f5f7fa;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}

.role-select {
  width: 100%;
}

.role-disabled-item-content {
  [dir='ltr'] & .lock-icon {
    margin-left: auto;
    margin-right: 0;
  }

  [dir='rtl'] & .lock-icon {
    margin-right: auto;
    margin-left: 0;
  }
}
</style>
