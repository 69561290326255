var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.membershipsLoading),expression:"membershipsLoading"}],attrs:{"custom-class":"memberships-table","data":_vm.memberships,"columns":_vm.columns,"show-index":"","rounded":"","border":""},scopedSlots:_vm._u([{key:"cell-actions",fn:function({ rowIndex }){return [(_vm.hasPermissionToEditRole(_vm.memberships[rowIndex].role))?_c('el-dropdown',{staticClass:"d-flex justify-content-center",attrs:{"trigger":"click"},on:{"command":(command) => _vm.handleAction(command, rowIndex)}},[_c('Button',{staticClass:"actions-btn text-typography-primary",attrs:{"id":`actions-row-${rowIndex}`,"type":"icon"}},[_c('KebabIcon')],1),_c('el-dropdown-menu',[_c('el-dropdown-item',{attrs:{"command":_vm.ACTIONS.EDIT_USER}},[_c('div',{staticClass:"d-flex align-items-center gap-2 ps-2"},[_c('EditIcon',{attrs:{"size":26}}),_c('p',[_vm._v(_vm._s(_vm.$t('memberships.membershipTable.actions.edit')))])],1)]),_c('el-dropdown-item',{attrs:{"command":_vm.ACTIONS.DELETE_USER_ACCESS}},[_c('div',{staticClass:"action-delete d-flex align-items-center gap-2 ps-2"},[_c('UnlinkV2Icon'),_c('p',[_vm._v(_vm._s(_vm.$t('memberships.membershipTable.actions.delete')))])],1)])],1)],1):_vm._e()]}},{key:"cell-name",fn:function({
      rowData: {
        user: { firstName, lastName, picture },
      },
    }){return [_c('div',{staticClass:"name"},[(picture)?[_c('img',{attrs:{"src":`${picture}&token=${_vm.token}`,"alt":"","width":"24","height":"24"}})]:[_c('div',{staticClass:"bg-secondary rounded-pill d-flex justify-content-center align-items-center",staticStyle:{"height":"24px","width":"24px"}},[_c('small',{staticClass:"icon-text"},[_vm._v(_vm._s(firstName[0] + lastName[0]))])])],_c('p',[_vm._v(_vm._s(`${firstName} ${lastName}`))])],2)]}},{key:"cell-email",fn:function({
      rowData: {
        user: { email },
      },
    }){return [_vm._v(" "+_vm._s(email)+" ")]}},{key:"cell-phoneNumber",fn:function({
      rowData: {
        user: { phone_number },
      },
    }){return [_vm._v(" "+_vm._s(phone_number ? phone_number : '-')+" ")]}},{key:"cell-role",fn:function({ rowData: { role } }){return [(_vm.isCurrentTenantBookkeeping && role === _vm.ROLE_TYPE.MANAGER)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('memberships.membershipTable.role.bookkeepingEmployee'))+" ")]):(_vm.isCurrentTenantSupplier && role === _vm.ROLE_TYPE.EMPLOYEE)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('memberships.membershipTable.role.supplierEmployee'))+" ")]):_c('p',[_vm._v(_vm._s(_vm.$t(`memberships.membershipTable.role.${role}`)))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }